import * as React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import locale from '../localization';
import Layout from '../components/layout';
import H1 from '../components/h1';
import { MainA } from '../components/MainLink';

const INSTAGRAM_ART_LINK = 'https://www.instagram.com/art_.anna._we/';
const INSTAGRAM_ME_LINK = 'https://www.instagram.com/me_.anna._we/';

const Grid = styled.div`
  margin-top: 100px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(275px, 1fr));
`;

const TitleItem = styled.div`
  text-align: center;
  margin: 315px auto 0 auto;
`;

const GridItem = styled.div`
  margin: 50px 0;
  text-align: center;
`;

const LandingPageH1 = styled(H1)`
  margin-bottom: 10px;
`;

function IndexPage({ data }) {
  return (
    <Layout>
      <TitleItem>
        <LandingPageH1>
          {locale('name')}
        </LandingPageH1>
        <MainA textTransform="uppercase">{locale('nameDescription')}</MainA>
      </TitleItem>
      <Grid>
        <GridItem>
          <LandingPageH1>
            {locale('architecture')}
          </LandingPageH1>
          <MainA textTransform="uppercase" target="_blank" href={data.portfolio.file.url}>{locale('linkPortfolio')}</MainA>
        </GridItem>
        <GridItem>
          <LandingPageH1>
            {locale('art')}
          </LandingPageH1>
          <MainA href={INSTAGRAM_ART_LINK} target="_blank">{locale('linkInstagramArt')}</MainA>
        </GridItem>
        <GridItem>
          <LandingPageH1>
            {locale('about')}
          </LandingPageH1>
          <p>
            <MainA href={INSTAGRAM_ME_LINK} target="_blank">{locale('linkInstagramMe')}</MainA>
          </p>
          <p>
            <MainA textTransform="uppercase" target="_blank" href={data.cv.file.url}>{locale('linkCV')}</MainA>
          </p>
        </GridItem>
      </Grid>
    </Layout>
  );
}

export const pageQuery = graphql`
  query {
    cv: contentfulFile(name: {eq: "cv"}) {
      name
      file {
        url
      }
    }
    portfolio: contentfulFile(name: {eq: "portfolio"}) {
      name
      file {
        url
      }
    }
  }
`;

export default IndexPage;
